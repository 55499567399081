<template>
  <div class="bitsea_collections_page">
    <div class="bitsea_collections_page_01">
      <Menu/>
    </div>

    <img id="bitsea_collections_page_bj" src="../../assets/images/bja.png" class="bitsea_bj_img">

    <div id="bitsea_collections_page_02" class="bitsea_collections_page_02">
      <div id="bitsea_collections_page_02_divs" class="bitsea_collections_page_02_divs">
        <div class="bitsea_collections_page_021">
          <div class="bitsea_collections_page_0211">
            <div class="bitsea_collections_page_0211_alls">
              <img class="bitsea_collections_page_02111" :src="colBitseaNfts[selectNftIndex].img">

              <div class="bitsea_collections_page_02112">
                <div class="bitsea_collections_page_021121">
                  <div class="bitsea_collections_page_0211211">{{colBitseaNfts[selectNftIndex].name}}</div>
                  <div class="bitsea_collections_page_0211212">
<!--                    <img src="../../assets/images/Icon.png"/>-->
                    <a v-if="colBitseaNfts[selectNftIndex].details.linkc !== null" :href="colBitseaNfts[selectNftIndex].details.linkc" target="_blank">
                      <img src="../../assets/images/Vector4.png"/>
                    </a>
                  </div>
                </div>

                <img class="bitsea_collections_page_021122" src="../../assets/images/h1bar_div.png"/>

                <div :class="isLaunchCol ? 'bitsea_collections_page_021123_pc' : 'bitsea_collections_page_021123'">
                  {{colBitseaNfts[selectNftIndex].details.describe}}
                </div>

                  <div class="bitsea_collections_page_021123_01" @click="isLaunchCol = !isLaunchCol">
                      <i v-if="!isLaunchCol" class="el-icon-arrow-down"></i>
                      <i v-if="isLaunchCol" class="el-icon-arrow-up"></i>
                  </div>

              </div>
            </div>
          </div>

          <div class="bitsea_collections_page_0212">
            <div class="bitsea_collections_page_02121">
              <div class="bitsea_collections_page_021211">
                FLOOR PRICE
              </div>
              <div class="bitsea_collections_page_021212">
                <div>{{setDecimal(collectionsDataObj.floorPrice/1e18, 6)}}</div>
                  <img v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502" src="../../assets/images/SVG.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212" src="../../assets/images/mapo.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 223" src="../../assets/images/bTwo.png"/>
              </div>
            </div>

            <div class="bitsea_collections_page_02121">
              <div class="bitsea_collections_page_021211">
                SALES
              </div>
              <div class="bitsea_collections_page_021212">
                {{collectionsDataObj.sales}}
              </div>
            </div>

            <div class="bitsea_collections_page_02121">
              <div class="bitsea_collections_page_021211">
                24 H VOLUME
              </div>
              <div class="bitsea_collections_page_021212">
                <div>{{setDecimal(collectionsDataObj.dayVolume/1e18, 6)}}</div>
                  <img v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502" src="../../assets/images/SVG.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212" src="../../assets/images/mapo.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 223" src="../../assets/images/bTwo.png"/>
              </div>
            </div>

            <div class="bitsea_collections_page_02121">
              <div class="bitsea_collections_page_021211">
                TOTAL VOLUME
              </div>
              <div class="bitsea_collections_page_021212">
<!--                <div>{{setDecimal((parseFloat(collectionsDataObj.floorPrice)*parseInt(collectionsDataObj.supply))/1e18, 6)}}</div>-->
                <div>{{setDecimal(collectionsDataObj.totalVolume/1e18, 6)}}</div>
                  <img v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502" src="../../assets/images/SVG.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212" src="../../assets/images/mapo.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 223" src="../../assets/images/bTwo.png"/>
              </div>
            </div>

            <div class="bitsea_collections_page_02121">
              <div class="bitsea_collections_page_021211">
                MARKET CAP
              </div>
              <div class="bitsea_collections_page_021212" v-if="colBitseaNfts[selectNftIndex].name === 'MonsterLand404'">
                  <div>{{setDecimal((parseFloat(collectionsDataObj.floorPrice) * parseInt(collectionsDataObj.supply / 1e18))/1e18, 6)}}</div>
                  <img v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502" src="../../assets/images/SVG.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212" src="../../assets/images/mapo.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 223" src="../../assets/images/bTwo.png"/>
              </div>
              <div class="bitsea_collections_page_021212" v-else>
                  <div v-if="colBitseaNfts[selectNftIndex].name === 'RyeharvestBadge'">{{setDecimal((parseFloat(collectionsDataObj.floorPrice) * parseInt(collectionsDataObj.listed))/1e18, 6)}}</div>
                  <div v-else>{{setDecimal((parseFloat(collectionsDataObj.floorPrice) * parseInt(collectionsDataObj.supply))/1e18, 6)}}</div>
                  <img v-show="parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502" src="../../assets/images/SVG.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212" src="../../assets/images/mapo.png"/>
                  <img v-show="parseInt($store.state.networkIDstring) === 223" src="../../assets/images/bTwo.png"/>
              </div>
            </div>

            <div class="bitsea_collections_page_02121">
              <div class="bitsea_collections_page_021211">
                LISTED
              </div>
              <div class="bitsea_collections_page_021212">
                {{collectionsDataObj.listed}}
              </div>
            </div>

            <div class="bitsea_collections_page_02121">
              <div class="bitsea_collections_page_021211">
                SUPPLY
              </div>
              <div class="bitsea_collections_page_021212" v-if="colBitseaNfts[selectNftIndex].name === 'MonsterLand404'">
                {{(collectionsDataObj.supply / 1e18) < 1 ? 0 : parseInt(collectionsDataObj.supply / 1e18)}}
              </div>
              <div class="bitsea_collections_page_021212" v-else>
                {{
                  colBitseaNfts[selectNftIndex].name === 'RyeharvestBadge' ?
                  " -- " :
                  (collectionsDataObj.supply > 10000000000 ? 0 : collectionsDataObj.supply)
                }}
              </div>
            </div>

            <div class="bitsea_collections_page_02121">
              <div class="bitsea_collections_page_021211">
                OWNERS
              </div>
              <div class="bitsea_collections_page_021212">
                {{collectionsDataObj.owners}}
              </div>
            </div>
          </div>
        </div>

        <div class="bitsea_collections_page_022">
          <div class="bitsea_collections_page_0221">

            <div class="bitsea_collections_page_02211">
              <img src="../../assets/images/Icon2.png"/>
              <input placeholder="Search" v-model="searchKey" @input="searchNft(searchKey)" @keyup="searchNft(searchKey)"/>
            </div>

            <div class="bitsea_collections_page_02213">
              <div class="bitsea_collections_page_023a121">
                <div class="bitsea_collections_page_023a1211">
                  Name
                </div>
                <div class="bitsea_collections_page_023a1212">
                  Value
                </div>
              </div>

              <div class="bitsea_collections_page_023a122">
                <div class="bitsea_collections_page_023a1221"
                     v-if="colBitseaNfts.length > 0"
                     :style="selectNftIndex === key ? 'background: rgba(17, 17, 17, 0.35); border-radius: 5px;' : ''"
                     v-show="(searchKey && searchKey !== null && searchKey !== '') ? (obj.name.toString().indexOf(searchKey.toString()) >= 0) : true"
                     v-for="(obj, key) in colBitseaNfts" :key="key" @click="toSelectNftObj(obj, key, true)">
                  <div class="bitsea_collections_page_023a12211">
                    <img :src="obj.img"/>
                    <div class="bitsea_collections_page_023a122111">
                      <div class="bitsea_collections_page_023a1221111">
                        {{ obj.name }}
                      </div>
                      <div class="bitsea_collections_page_023a1221112" v-if="myNftAllNum.length >= colBitseaNfts.length">
                        Listed {{myNftAllNum[key].goodsNum}} / {{obj.name === "MonsterLand404" ? parseInt(myNftAllNum[key].nftNum / 1e18) : myNftAllNum[key].nftNum}}
                      </div>
                      <div class="bitsea_collections_page_023a1221112" v-else>
                        Listed 0 / 0
                      </div>
                    </div>
                  </div>

                  <div class="bitsea_collections_page_023a12212">
                    <div class="bitsea_collections_page_023a122121">
                      <!--                                                $9,945.1-->
                    </div>
                    <div class="bitsea_collections_page_023a122122">
                      <!--                                                +3.57%-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
<!--            <div class="bitsea_collections_page_02212">-->
<!--              <div class="bitsea_collections_page_022121">-->
<!--                <el-collapse v-model="activeName" accordion>-->
<!--                  <el-collapse-item title="Background" name="1">-->
<!--                    <div>Background</div>-->
<!--                  </el-collapse-item>-->
<!--                  <el-collapse-item title="Body" name="2">-->
<!--                    <div>Body</div>-->
<!--                  </el-collapse-item>-->
<!--                  <el-collapse-item title="Clothing" name="3">-->
<!--                    <div>Clothing</div>-->
<!--                  </el-collapse-item>-->
<!--                  <el-collapse-item title="Eyes" name="4">-->
<!--                    <div>Eyes</div>-->
<!--                  </el-collapse-item>-->
<!--                </el-collapse>-->
<!--              </div>-->
<!--            </div>-->
          </div>

          <div class="bitsea_collections_page_022_all">

              <div class="mob">
                  <div class="bitsea_collections_page_022_all_00" @click="collectionsDrawer = true">
                      <div>Please Select Collections</div>
                      <img src="../../assets/images/switch-horizontal-01.png"/>
                  </div>
              </div>

            <div class="bitsea_collections_page_022_all_01">
              <div class="bitsea_collections_page_0222">
                <div @click="onSelectType(1)" :class="selectTypeIndex === 1 ? 'bitsea_collections_page_0222_01 bitsea_collections_page_0222_select' : 'bitsea_collections_page_0222_01'">
                  Listed
                </div>
                <div @click="onSelectType(2)" :class="selectTypeIndex === 2 ? 'bitsea_collections_page_0222_02 bitsea_collections_page_0222_select' : 'bitsea_collections_page_0222_02'">
                  Orders
                </div>
<!--                <div @click="onSelectType(3)" :class="selectTypeIndex === 3 ? 'bitsea_collections_page_0222_03 bitsea_collections_page_0222_select' : 'bitsea_collections_page_0222_03'">-->
<!--                  Charts-->
<!--                </div>-->
              </div>

              <div class="bitsea_collections_page_022_all_011">
<!--                <div class="bitsea_collections_page_022_all_0111">-->
<!--                  <img src="../../assets/images/Icon2.png"/>-->
<!--                  <input placeholder="Search"/>-->
<!--                </div>-->
                <div class="bitsea_collections_page_022_all_0112" v-show="selectTypeIndex === 1" @click="onQueryPriceAscOrDesc()">
                    <div class="bitsea_collections_page_022_all_01121_ss">
                        <div class="bitsea_collections_page_022_all_01121">
                            {{ priceAscOrDesc === 0 ? "Price: low to high" : (priceAscOrDesc === 1 ? "Price: high to low" : (priceAscOrDesc === 2 ? "Time: early to later" : "Time: later to early"))}}
                        </div>
                        <img src="../../assets/images/sreach_arrow.png" :style="isPriceAscOrDesc ? '' : 'transform: rotate(180deg);'"/>
                    </div>
                    <div class="bitsea_collections_page_022_all_01121_ssAll" v-show="isPriceAscOrDesc">
                        <div @click="queryPriceAscOrDesc(0)">Price: low to high</div>
                        <div @click="queryPriceAscOrDesc(1)">Price: high to low</div>
                        <div @click="queryPriceAscOrDesc(2)">Time: early to later</div>
                        <div @click="queryPriceAscOrDesc(3)">Time: later to early</div>
                    </div>
                </div>

                <div class="bitsea_collections_page_022_all_0112_b" v-show="selectTypeIndex === 2" @click="onOrdersType()">
                    <div class="bitsea_collections_page_022_all_01121_a">
                        <div class="bitsea_collections_page_022_all_01121_b">
                            {{ transferOrdersType === 0 ? "ALL" : (transferOrdersType === 1 ? "LISTED" : (transferOrdersType === 2 ? "CANCELED" : "SOLD"))}}
                        </div>
                        <img src="../../assets/images/sreach_arrow.png" :style="isTransferOrdersType ? '' : 'transform: rotate(180deg);'"/>
                    </div>
                    <div class="bitsea_collections_page_022_all_01121_all" v-show="isTransferOrdersType">
                        <div @click="toOrdersType(0)">ALL</div>
                        <div @click="toOrdersType(1)">LISTED</div>
                        <div @click="toOrdersType(2)">CANCELED</div>
                        <div @click="toOrdersType(3)">SOLD</div>
                    </div>
                </div>
              </div>
            </div>

              <div class="bitsea_collections_page_0223_listing_01" v-show="selectTypeIndex === 1">
                  <div @click="toBulkPurchase(listedListData)" class="bitsea_collections_page_0223_listing_011 mob">Sweep</div>
                  <el-checkbox :indeterminate="buyIsIndeterminate" v-model="buyCheckAll" @change="buyHandleCheckAllChange">All</el-checkbox>
                  <div @click="toBulkPurchase(listedListData)" class="bitsea_collections_page_0223_listing_012 com">Sweep</div>
              </div>

            <div class="bitsea_collections_page_0223" v-if="selectTypeIndex === 1">

                <el-checkbox-group v-model="buyCheckedCities" @change="buyHandleCheckedCitiesChange">
              <div class="bitsea_collections_page_0223_01" v-if="listedListData.length > 0" v-for="(obj, key) in listedListData" :key="key">
                <img src="../../assets/images/homepage_bannercard.png" class="bitsea_collections_page_0223_011_bj"/>

                <img :src="colIpfsUrl + (parseInt(obj.CTokenID) > 10000 ? parseInt(parseInt(obj.CTokenID) % 10000) : obj.CTokenID) + '.png'" class="bitsea_collections_page_0223_011" v-if="selectNftObj.name === 'MonsterLand404' && !selectNftObj.isTokenUri"/>
                <img :src="obj.CUri" class="bitsea_collections_page_0223_011" v-if="selectNftObj.name !== 'MonsterLand404' && !selectNftObj.isTokenUri"/>
                <img :src="selectNftObj.tokenUri + obj.CTokenID + '.png'" class="bitsea_collections_page_0223_011" v-if="selectNftObj.isTokenUri"/>

                  <div class="bitsea_collections_page_0223_011_checkbox">
                      <el-checkbox :label="parseInt(obj.CGoodsID)" />
                  </div>

                <div class="bitsea_collections_page_0223_012">
                  <div class="collections_page_0223_0121">{{obj.CName}} #{{obj.CTokenID}}</div>
                  <div class="collections_page_0223_0122"><!-- Bitcoin Wizards.CO --></div>
                  <div class="collections_page_0223_0123">
                    <div class="collections_page_0223_0123_01">
                      <div>{{ BigNumberDiv(obj.CPrice, 1e18) }}
                          {{ (parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502) ? 'BTC' : ''}}
                          {{ (parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212) ? 'MAPO' : ''}}
                          {{ (parseInt($store.state.networkIDstring) === 223) ? 'BTC' : ''}}
                      </div>
                    </div>
                    <div class="collections_page_0223_0123_02">
<!--                      +3.57%-->
                    </div>
                  </div>
                </div>
                <img v-show="($store.state.accounts.length > 0 && obj.COwner) ? ($store.state.accounts[0].toUpperCase() !== obj.COwner.toUpperCase()) : true" @click="onBuy(obj)" src="../../assets/images/Group1275.png" class="bitsea_collections_page_0223_013"/>
                <div v-show="!(($store.state.accounts.length > 0 && obj.COwner) ? ($store.state.accounts[0].toUpperCase() !== obj.COwner.toUpperCase()) : true)" class="bitsea_collections_page_0223_014" @click="offSell(obj.CGoodsID)">CANCEL</div>
              </div>
                </el-checkbox-group>

              <div class="bitsea_collections_page_0223_02" v-if="listedListData.length <= 0">
                {{ listedListDataLoading ? "Loading..." : "Not Data" }}
              </div>
            </div>

            <div class="bitsea_collections_page_0223a_page" v-if="selectTypeIndex === 1 && listedListData.length > 0">

              <el-pagination
                      background
                      @prev-click="initListedPageData"
                      @next-click="initListedPageData"
                      @current-change="initListedPageData"
                      layout="prev, pager, next"
                      :page-size="100"
                      :current-page="listedPageNum"
                      :total="listedTotal">
              </el-pagination>
            </div>

            <div class="bitsea_collections_page_0224" v-if="selectTypeIndex === 2">
              <div class="bitsea_collections_page_023b1">
                <div class="bitsea_collections_page_023b11"></div>
                <div class="bitsea_collections_page_023b12">
                  Item
                </div>
                <div class="bitsea_collections_page_023b13">
                  Price
                </div>
                <div class="bitsea_collections_page_023b14">
                  Quantity
                </div>
                <div class="bitsea_collections_page_023b15">
                  From
                </div>
                <div class="bitsea_collections_page_023b16">
                  To
                </div>
                <div class="bitsea_collections_page_023b17">
                  Time
                </div>
              </div>
              <div class="bitsea_collections_page_023b2">
                <div class="bitsea_collections_page_023b21" v-if="ordersListData.length > 0" v-for="(obj, key) in ordersListData" :key="key">
                  <div class="bitsea_collections_page_023b211" v-if="obj.returnValues.transactionType">
                    <img v-show="parseInt(obj.returnValues.transactionType) === 1" src="../../assets/images/tag-02.png"/>
                    <img v-show="parseInt(obj.returnValues.transactionType) === 2" src="../../assets/images/switch-horizontal-01.png"/>
                    <img v-show="parseInt(obj.returnValues.transactionType) === 3" src="../../assets/images/Frame.png"/>
                    <div class="bitsea_collections_page_023b2111" v-if="obj.returnValues.transactionType">
                      {{parseInt(obj.returnValues.transactionType) === 1 ? 'Listed' : (parseInt(obj.returnValues.transactionType) === 2 ? 'Canceled' : 'Sold')}}
                    </div>
                  </div>
                  <div class="bitsea_collections_page_023b212" v-if="selectNftObj.name === 'MonsterLand404' && !selectNftObj.isTokenUri">
                      <img :src="colIpfsUrl + (parseInt(obj.returnValues.nftId) > 10000 ? parseInt(parseInt(obj.returnValues.nftId) % 10000) : obj.returnValues.nftId) + '.png'"/>
                      <div>#{{obj.returnValues.nftId}}</div>
                  </div>
                  <div class="bitsea_collections_page_023b212" v-if="selectNftObj.name !== 'MonsterLand404' && !selectNftObj.isTokenUri">
                      <img :src="selectNftObj.img"/>
                      <div>#{{obj.returnValues.nftId}}</div>
                  </div>
                  <div class="bitsea_collections_page_023b212" v-if="selectNftObj.isTokenUri">
                      <img :src="selectNftObj.tokenUri + obj.returnValues.nftId + '.png'"/>
                      <div>#{{obj.returnValues.nftId}}</div>
                  </div>
                  <div class="bitsea_collections_page_023b213">
                    {{parseInt(obj.returnValues.price) === 0 ? 0 : BigNumberDiv(obj.returnValues.price, 1e18)}}
                      {{ (parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502) ? 'BTC' : ''}}
                      {{ (parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212) ? 'MAPO' : ''}}
                      {{ (parseInt($store.state.networkIDstring) === 223) ? 'BTC' : ''}}
                  </div>
                  <div class="bitsea_collections_page_023b214">
                    {{obj.returnValues.quantity}}
                  </div>
                  <div class="bitsea_collections_page_023b215" v-if="obj.returnValues.forAddress">
                    {{ $store.state.accounts.length > 0 ? (obj.returnValues.forAddress.toLowerCase() === $store.state.accounts[0].toLowerCase() ? 'You' : cutAccount(obj.returnValues.forAddress, 5)) : cutAccount(obj.returnValues.forAddress, 5) }}
                  </div>
                  <div class="bitsea_collections_page_023b216" v-if="obj.returnValues.forAddress">
                    {{ $store.state.accounts.length > 0 ? (obj.returnValues.toAddress.toLowerCase() === $store.state.accounts[0].toLowerCase() ? 'You' : cutAccount(obj.returnValues.toAddress, 5)) : cutAccount(obj.returnValues.toAddress, 5) }}
                  </div>
                  <div class="bitsea_collections_page_023b217">
                    {{obj.returnValues.timestamp ? new Date(obj.returnValues.timestamp * 1000).toUTCString() : '--'}}
                  </div>
                </div>
                <div class="bitsea_collections_page_023b22" v-if="ordersListData.length <= 0">
                  {{ ordersListDataLoading ? "Loading..." : "Not Data" }}
                </div>
              </div>
            </div>

            <div class="bitsea_collections_page_0224a_page" v-if="selectTypeIndex === 2 && ordersListData.length > 0">

              <el-pagination
                      background
                      @prev-click="initOrdersPageData"
                      @next-click="initOrdersPageData"
                      @current-change="initOrdersPageData"
                      layout="prev, pager, next"
                      :page-size="20"
                      :current-page="ordersPageNum"
                      :total="ordersTotal">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>

      <div class="bitsea_collections_page_03">
        <Bottom/>
      </div>
    </div>

    <div class="collections_buy_dialog_modal" v-if="collectionsBuyDialog">
      <div class="collections_buy_dialog_div">
        <div class="collections_buy_dialog_div_01">
          <div>Buy Item</div>
          <img src="../../assets/images/icon_close.png" @click="closeBuyDialog"/>
        </div>

        <div class="collections_buy_dialog_div_02">
          <div class="collections_buy_dialog_div_021">
            <img class="collections_buy_dialog_div_0211" :src="buyNftObj.nftImg"/>
            <div class="collections_buy_dialog_div_0212">
              <div class="collections_buy_dialog_div_02121">
                {{buyNftObj.nftName}} #{{buyNftObj.nftId}}
              </div>
              <div class="collections_buy_dialog_div_02122">
<!--                Bitcoin bitsea.CO-->
              </div>
            </div>
          </div>
          <div class="collections_buy_dialog_div_022">
            <div class="collections_buy_dialog_div_0221">
              <div class="collections_buy_dialog_div_02211">
<!--                Network Fee-->
              </div>
              <div class="collections_buy_dialog_div_02212">
                Serivice Fee
              </div>
              <div class="collections_buy_dialog_div_02213">
                Price
              </div>
            </div>
            <div class="collections_buy_dialog_div_0222">
              <div class="collections_buy_dialog_div_02221">
<!--                0.1%-->
              </div>
              <div class="collections_buy_dialog_div_02222">
                {{buyNftObj.serverFee}}%
              </div>
              <div class="collections_buy_dialog_div_02223">
                {{setDecimal(buyNftObj.nftPrice/1e18, 6)}}
                  {{ (parseInt($store.state.networkIDstring) === 11501 || parseInt($store.state.networkIDstring) === 1502) ? 'BTC' : ''}}
                  {{ (parseInt($store.state.networkIDstring) === 22776 || parseInt($store.state.networkIDstring) === 212) ? 'MAPO' : ''}}
                  {{ (parseInt($store.state.networkIDstring) === 223) ? 'BTC' : ''}}
              </div>
            </div>
          </div>
          <div class="collections_buy_dialog_div_023">
            By clicking “list”,you agree to the BitSea Terms of Service.
          </div>
        </div>

        <div class="collections_buy_dialog_div_03">
          <div class="collections_buy_dialog_div_031" @click="closeBuyDialog">
            Cancel
          </div>
          <div class="collections_buy_dialog_div_032" @click="toBuy(buyNftObj)">
            Buy
          </div>
        </div>
      </div>
    </div>

      <el-drawer
              title="Collections"
              direction="rtl"
              size="280px"
              :append-to-body="true"
              :visible.sync="collectionsDrawer"
              :with-header="false">
          <div class="bitsea_collections_page_drawer">
              <div class="bitsea_collections_page_drawer_01">
                  <img src="../../assets/images/close.png" @click="collectionsDrawer = false"/>
              </div>

              <div class="bitsea_collections_page_drawer_02">
                  Collections
              </div>

              <div class="bitsea_collections_page_drawer_alls">

                  <div class="bitsea_collections_page_02211">
                      <img src="../../assets/images/Icon2.png"/>
                      <input placeholder="Search" v-model="searchKey" @input="searchNft(searchKey)" @keyup="searchNft(searchKey)"/>
                  </div>

                  <div class="bitsea_collections_page_02213">
                      <div class="bitsea_collections_page_023a121">
                          <div class="bitsea_collections_page_023a1211">
                              Name
                          </div>
                          <div class="bitsea_collections_page_023a1212">
                              Value
                          </div>
                      </div>

                      <div class="bitsea_collections_page_023a122">
                          <div class="bitsea_collections_page_023a1221"
                               :style="selectNftIndex === key ? 'background: rgb(211 129 23); border-radius: 5px;' : ''"
                               v-if="colBitseaNfts.length > 0"
                               v-show="(searchKey && searchKey !== null && searchKey !== '') ? (obj.name.toString().indexOf(searchKey.toString()) >= 0) : true"
                               v-for="(obj, key) in colBitseaNfts" :key="key" @click="toSelectNftObj(obj, key, true)">
                              <div class="bitsea_collections_page_023a12211">
                                  <img :src="obj.img"/>
                                  <div class="bitsea_collections_page_023a122111">
                                      <div class="bitsea_collections_page_023a1221111">
                                          {{ obj.name }}
                                      </div>
                                      <div class="bitsea_collections_page_023a1221112" v-if="myNftAllNum.length >= colBitseaNfts.length">
                                          Listed {{myNftAllNum[key].goodsNum}} / {{obj.name === "MonsterLand404" ? parseInt(myNftAllNum[key].nftNum / 1e18) : myNftAllNum[key].nftNum}}
                                      </div>
                                      <div class="bitsea_collections_page_023a1221112" v-else>
                                          Listed 0 / 0
                                      </div>
                                  </div>
                              </div>

                              <div class="bitsea_collections_page_023a12212">
                                  <div class="bitsea_collections_page_023a122121">
                                      <!--                                                $9,945.1-->
                                  </div>
                                  <div class="bitsea_collections_page_023a122122">
                                      <!--                                                +3.57%-->
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </div>
      </el-drawer>

  </div>
</template>

<script>
  import Menu from "../../components/Menu";
  import Bottom from "../../components/Bottom";
  import toolUtils from "../../tools/toolUtils";
  import { mixin1 } from "../../tools/mixins";
  import * as metaMaskTools from "../../tools/chain";

  import transfer from "../../tools/transfer";
  import {
    debug
  } from "../../config";
  import {
      ipfsUrl,
      openUrlSLD,
      bitseaNfts,
      controlAddress,
      shopAddress,
      monsterLandAddress
  } from "../../tools/projectConfig";
  import {BigNumberMul, BigNumberDiv, cutAccount} from "../../tools";

  export default {
    name: "Index",
    components: { Menu, Bottom },
    mixins: [ mixin1 ],
    data() {
      return {
        // ipfsUrl,
          colIpfsUrl: "https://nftstorage.link/ipfs/bafybeihdydvg2dqkyo3gnaeptahddg3gk5cu6vzeadxwkixd5bqbjruobe/",
        // bitseaNfts,
          colBitseaNfts: [],
        collectionsDrawer: false,
        myBtcBalance: 0,
        collectionsDataObj: {
          floorPrice: 0,
          sales: 0,
          dayVolume: 0,
          totalVolume: 0,
          marketCap: 0,
          listed: 0,
          supply: 0,
          owners: 0
        },
        isTransferOrdersRun: false,
        isTransferOrdersType: false,
        transferOrdersType: 0,
        searchKey: null,
        myNftAllNum: [],
        selectNftIndex: 0,
        selectNftObj: {},
        bitseaPageWidth: null,
        bitseaPageHeight: 0,
        activeName: 1,
        selectTypeIndex: 1,
        collectionsBuyDialog: false,
        buyNftObj: {},
        priceAscOrDesc: 0,
        isPriceAscOrDesc: false,
        listedListDataLoading: false,
        listedPageNum: 1,
        listedTotal: 0,
        listedListData: [],
        ordersListDataLoading: false,
        ordersPageNum: 1,
        ordersTotal: 0,
        ordersListData: [],
        ordersListDataAll: [],
        chartsListDataLoading: false,
        chartsPageNum: 1,
        chartsTotal: 0,
        chartsListData: [],
        isLaunchCol: false,
        buyCheckAll: false,
        buyCheckedCities: [],
        buyCities: [],
        buyIsIndeterminate: false
      }
    },
    watch: {},
    created() {
        transfer.$on("collectionsInitData", (data) => {
            this.collectionsInitData(data);
        });

        this.initColData();

      setTimeout(() => {
        this.onAccountsChanged();
      }, 1000);
    },
    mounted() {
        this.initColData();

      setTimeout(() => {
          this.initPageData();
      }, 500);

      window.onresize = () => {
        return (() => {
          this.setBitseaPageHeight();
        })();
      }

      setTimeout(() => {
        this.setBitseaPageHeight();
      }, 10);
      setTimeout(() => {
        this.setBitseaPageHeight();
      }, 300);
    },
    destroyed() {
    },
    methods: {
      async onRefresh(accounts) {
        this.$store.commit('setState', {
          accounts
        });

        await this.getBalance();
        await this.getMyNftAllNum();
        if (bitseaNfts.length > 0) {
            await this.toSelectNftObj(bitseaNfts[this.selectNftIndex], this.selectNftIndex);
        }
        await this.initNftData();

        setTimeout(async () => {
          await this.setBitseaPageHeight();
        }, 10);
      },
        async initColData() {
            this.colIpfsUrl = ipfsUrl;
            this.colBitseaNfts = bitseaNfts;
        },
        async collectionsInitData(data) {
            this.listedListData = [];
            this.ordersPageNum = 1;
            this.listedPageNum = 1;
            this.selectNftIndex = 0;
            await this.initPageData();

            setTimeout(async () => {
                await this.setBitseaPageHeight();
            }, 500);
        },
      async initPageData() {
          await this.initColData();

        await metaMaskTools.initParams().then(() => {});

        await this.getBalance();
        await this.getMyNftAllNum();
        if (bitseaNfts.length > 0) {
            await this.toSelectNftObj(bitseaNfts[this.selectNftIndex], this.selectNftIndex);
        }
        await this.initNftData();

        setTimeout(async () => {
          await this.setBitseaPageHeight();
        }, 10);
      },
      setBitseaPageHeight() {
        let divWidth = document.getElementById("bitsea_collections_page_02").clientWidth;
        let divHeight = document.getElementById("bitsea_collections_page_02").clientHeight;
        let divHeights = document.getElementById("bitsea_collections_page_02_divs").clientHeight;

        if (divHeights + 100 > divHeight) {
          document.getElementById("bitsea_bottom_page").style.position = "relative";
        } else {
          document.getElementById("bitsea_bottom_page").style.position = "absolute";
        }

        let bjActualWidth = ((divHeight / 1158) * 1920);
        if (divWidth < bjActualWidth) {
          $('#bitsea_collections_page_bj').animate({ width : bjActualWidth }, 0);
        } else {
          $('#bitsea_collections_page_bj').animate({ width : divWidth }, 0);
        }
      },
      cutAccount(account, num) {
        return cutAccount(account, num);
      },
      BigNumberDiv(account, num, col) {
          return BigNumberDiv(account, num, col);
      },
      setDecimal(numVal, numDecimalMax) {
        return toolUtils.setDecimal(numVal, numDecimalMax);
      },
      formatTimestamp(dateTime) {
        return toolUtils.formatTimestamp(dateTime);
      },
      async getBalance() {
        if (this.$store.state.accounts.length > 0) {
          try {
            await metaMaskTools.getBalance(this.$store.state.accounts[0]).then(async (data) => {
              this.myBtcBalance = parseInt(data);
            });
          } catch (err) {
            console.log(err);
          }
        } else {
          setTimeout(async () => {
            await this.getBalance();
          }, 10000);
        }

        setTimeout(async() => {
          await this.getBalance();
        }, 60000);
      },
      async getMyNftAllNum() {
        try {
          let myNftAllNumArr = [];

            for (let i = 0; i < bitseaNfts.length; i++) {
              let obj = {
                goodsNum: 0,
                nftNum: 0
              }

              if (bitseaNfts[i].address.toUpperCase() === "0X2D72CEA26311B9E96EDF2EB2423993D280592E7E") {
                  obj.nftNum = " -- ";
              } else {
                  await metaMaskTools.erc721BasicPublicCall(bitseaNfts[i].address, "totalSupply", []).then(async (total) => {
                      if (total) {
                          obj.nftNum = parseInt(total);
                      }
                  });
              }

              await metaMaskTools.getShopBalanceOf([bitseaNfts[i].address]).then(async (goodsNum) => {
                  obj.goodsNum = parseInt(goodsNum);
              });

              myNftAllNumArr.push(obj);
            }

          this.myNftAllNum = myNftAllNumArr;
        } catch (err) {
          console.log(err);
        }

        setTimeout(() => {
          this.getMyNftAllNum();
        }, 60000);
      },
      async searchNft(key) {},
      async initNftData() {
        try {
            if (this.selectNftObj && this.selectNftObj.address) {
                await this.$http.listdeal(1, 10, this.selectNftObj.address).then(async (res) => {
                    if (res) {
                        this.collectionsDataObj.floorPrice = res.FLOOR_RICE ? parseInt(res.FLOOR_RICE) : 0;
                        this.collectionsDataObj.dayVolume = res.Count24 ? parseInt(res.Count24) : 0;
                        this.collectionsDataObj.sales = res.count ? parseInt(res.count) : 0;
                        this.collectionsDataObj.totalVolume = res.count_ ? parseFloat(res.count_) : 0;
                    }
                });

                if (
                    this.selectNftObj.address &&
                    (
                        this.selectNftObj.address.toString().toUpperCase() === "0X09FF8E49D0EA411A3422ED95E8F5497D4241F532" ||
                        this.selectNftObj.address.toString().toUpperCase() === "0X2D72CEA26311B9E96EDF2EB2423993D280592E7E"
                    )
                ) {
                    await this.$http.getTokenHolders(this.selectNftObj.address).then(async (res) => {
                        if (res && res.token_holders_count) {
                            this.collectionsDataObj.owners = parseInt(res.token_holders_count);
                        } else {
                            this.collectionsDataObj.owners = "-";
                        }
                    });
                } else if (
                    this.selectNftObj.address &&
                    (this.selectNftObj.address.toString().toUpperCase() === "0X066466D7EAA56B60AAF0436DBDA6F92DB7BD2468" ||
                    this.selectNftObj.address.toString().toUpperCase() === "0X2704EC05C4491EBF54C9740396DD2E24F5F43BA2")
                ) {
                    await this.$http.getTokenDetail(this.selectNftObj.address).then(async (res) => {
                        if (res && res.result.data.json.holders) {
                            this.collectionsDataObj.owners = parseInt(res.result.data.json.holders);
                        } else {
                            this.collectionsDataObj.owners = "-";
                        }
                    });
                } else {
                    await this.$http.listcontract(this.selectNftObj.address).then(async (res) => {
                        if (res.data) {
                            this.collectionsDataObj.owners = parseInt(res.data[0]["COUNT(CContract)"]);
                        } else {
                            this.collectionsDataObj.owners = "-";
                        }
                    });
                }

                await metaMaskTools.getShopBalanceOf([this.selectNftObj.address]).then(async (listed) => {
                    if (listed) {
                        this.collectionsDataObj.listed = parseInt(listed);
                    }
                });

                if (this.selectNftObj.address.toString().toUpperCase() === "0X2D72CEA26311B9E96EDF2EB2423993D280592E7E") {
                    this.collectionsDataObj.supply = 0;
                } else {
                    await metaMaskTools.erc721BasicPublicCall(this.selectNftObj.address, "totalSupply", []).then(async (total) => {
                        if (total) {
                            this.collectionsDataObj.supply = parseInt(total);
                        }
                    });
                }
            }

          // await metaMaskTools.getIndexBlock().then(async (block) => {
          //     let latestNum = parseInt(block);
          //     let fromNum = parseInt(this.selectNftObj.fromBlock);
          //
          //     let countSales = 0;
          //     for (let i = fromNum; i <= latestNum; i+= 10001) {
          //         let fBlock = i;
          //         let tBlock = i + 10000;
          //         if (tBlock >= latestNum) {
          //             tBlock = latestNum;
          //         }
          //
          //         await metaMaskTools.getNftTransaction(fBlock, tBlock, this.selectNftObj.address, 3).then(async (res) => {
          //             if (res && res.length > 0) {
          //                 countSales = countSales + res.length;
          //             }
          //         });
          //     }
          //
          //     this.collectionsDataObj.sales = countSales;
          // });

          // await metaMaskTools.getNftTransaction(this.selectNftObj.fromBlock, this.selectNftObj.address, 3).then(async (res) => {
          //   if (res && res.length > 0) {
          //       // let totalVolume = 0;
          //       // for (let i = 0; i < res.length; i++) {
          //       //     totalVolume = totalVolume + parseInt(res[i].returnValues.price);
          //       // }
          //       // this.collectionsDataObj.totalVolume = totalVolume;
          //
          //       this.collectionsDataObj.sales = res.length;
          //   }
          // });
        } catch (e) {
            console.log(e);
        }

        await setTimeout(() => {
          this.initNftData();
        }, 60000);
      },
      onQueryPriceAscOrDesc() {
          this.isPriceAscOrDesc = !this.isPriceAscOrDesc;
      },
      async queryPriceAscOrDesc(num) {
        this.isPriceAscOrDesc = !this.isPriceAscOrDesc;
        this.priceAscOrDesc = parseInt(num);
        this.listedListData = [];

        await this.toSelectNftObj(this.selectNftObj, this.selectNftIndex);
      },
      async toSelectNftObj(obj, key, isInit) {
          if (isInit) {
              this.collectionsDataObj = {
                  floorPrice: 0,
                  sales: 0,
                  dayVolume: 0,
                  totalVolume: 0,
                  marketCap: 0,
                  listed: 0,
                  supply: 0,
                  owners: 0
              };
          }

          this.collectionsDrawer = false;

        if (parseInt(this.selectNftIndex) !== parseInt(key)) {
          this.listedListData = [];

          this.isTransferOrdersType = false;
          this.ordersPageNum = 1;
          this.transferOrdersType = 0;
          this.ordersListData = [];
          this.ordersListDataAll = [];

            await this.initCheckbox();
        }

        this.selectNftIndex = parseInt(key);
        this.selectNftObj = obj;

        await this.initNftData();
        await this.onSelectType(this.selectTypeIndex);
      },
      onSelectType(index) {
          if (parseInt(index) === 2) {
              this.initCheckbox();
          }

        this.selectTypeIndex = parseInt(index);

        this.isTransferOrdersType = false;

        this.initCollectionsPageData();
      },
      async initCollectionsPageData() {
        if (this.selectTypeIndex === 1) {
          await this.initListedPageData(this.listedPageNum);
        } else if (this.selectTypeIndex === 2) {
            this.ordersListData = [];
            this.ordersListDataAll = [];
            if (this.isTransferOrdersRun) {
                this.isTransferOrdersRun = false;
            } else {
                await this.initOrdersPageData(this.ordersPageNum);
            }
        } else if (this.selectTypeIndex === 3) {
          await this.initChartsPageData();
        }
      },
      async initListedPageData(pageNum) {
        if (parseInt(this.listedPageNum) !== parseInt(pageNum)) {
          this.listedListData = [];
        }
        this.listedListDataLoading = true;
        this.listedPageNum = parseInt(pageNum);

        try {
          await this.initCheckbox();

          await metaMaskTools.getShopBalanceOf([this.selectNftObj.address]).then(async (total) => {
            if (total) {
              this.listedTotal = parseInt(total);
              this.collectionsDataObj.listed = parseInt(total);
            }
          });

          // await metaMaskTools.getGoodsList([pageNum, "0x0000000000000000000000000000000000000000"]).then(async (res) => {
          //   if (res) {
          //     let listedListDataArr = [];
          //     for (let i = 0; i < res[0].length; i++) {
          //       if (parseInt(res[1][i]) <= 0) {
          //         break;
          //       }
          //       await metaMaskTools.erc721BasicPublicCall(res[0][i], "symbol" ).then(async (name) => {
          //         let obj = {
          //           address: res[0][i],
          //           nftName: name,
          //           img: res[3][i],
          //           nftId: res[1][i],
          //           price: res[2][i],
          //         };
          //         listedListDataArr.push(obj);
          //       });
          //     }
          //     this.listedListData = listedListDataArr;
          //   }
          // });

            let sortNum = 10;

            if (this.priceAscOrDesc === 0) {
                sortNum = 10;
            } else if (this.priceAscOrDesc === 1) {
                sortNum = 11;
            } else if (this.priceAscOrDesc === 2) {
                sortNum = 20;
            } else if (this.priceAscOrDesc === 3) {
                sortNum = 21;
            }

          await this.$http.listgoods(this.listedPageNum, sortNum, '', this.selectNftObj.address).then(async (res) => {
            if (res.data) {
                this.buyCities = [];
              let listedListDataArr = [];

              for (let i = 0; i < res.data.length; i++) {
                  this.buyCities.push(parseInt(res.data[i].CGoodsID));

                  if (this.selectNftObj.address.toUpperCase() === "0X57C46671A01AAD9359D97F844916CCAB4BF6713C") {
                      res.data[i].CUri = this.selectNftObj.img;
                  }

                if (res.data[i].COwner === "") {
                  let obj = {
                    CGoodsID: res.data[i].CGoodsID,
                    CName: res.data[i].CName,
                    CAddress: res.data[i].CAddress,
                    COwner: "0x0000000000000000000000000000000000000000",
                    CTokenID: res.data[i].CTokenID,
                    CUri: res.data[i].CUri,
                    CType: res.data[i].CType,
                    CTimestamp: res.data[i].CTimestamp,
                    CPrice: res.data[i].CPrice,
                  };
                  await listedListDataArr.push(obj);

                  await metaMaskTools.getGoodsInfo([res.data[i].CGoodsID]).then(async (res) => {
                    if (res) {
                      listedListDataArr[i].COwner = res[1];
                    }
                  });
                } else {
                  await listedListDataArr.push(res.data[i]);
                }
              }

              this.listedListData = listedListDataArr;
            }
          });
        } catch (e) {
          console.log(e);
        }
        this.listedListDataLoading = false;

        setTimeout(async () => {
          await this.setBitseaPageHeight();
        }, 10);
      },
        async onOrdersType() {
          this.isTransferOrdersType = !this.isTransferOrdersType;
        },
        toOrdersType(type) {
          this.isTransferOrdersType = false;

          if (this.transferOrdersType !== parseInt(type)) {
              this.transferOrdersType = parseInt(type);

              this.ordersListData = [];
              this.ordersListDataAll = [];
              if (this.isTransferOrdersRun) {
                  this.isTransferOrdersRun = false;
              } else {
                  this.initOrdersPageData(1);
              }
          }
        },
      async initOrdersPageData(pageNum) {
        this.ordersListDataLoading = true;
        this.ordersPageNum = parseInt(pageNum);

        try {
            if (this.ordersListDataAll.length === 0) {
                this.isTransferOrdersRun = true;

                await metaMaskTools.getIndexBlock().then(async (block) => {
                    let latestNum = parseInt(block);
                    let fromNum = parseInt(this.selectNftObj.fromBlock);

                    let countData = [];
                    for (let i = latestNum; i >= fromNum; i -= 3001) {

                        if (!this.isTransferOrdersRun) {
                            this.ordersListData = [];
                            this.ordersListDataAll = [];
                            await this.initOrdersPageData(1);

                            return;
                        }

                        let tBlock = i;
                        let fBlock = (i > 3000) ? (i - 3000) : 0;
                        if (fBlock <= fromNum) {
                            fBlock = fromNum;
                        }

                        // console.log("Block: ", fBlock, tBlock);

                        let arrData = countData;
                        await metaMaskTools.getNftAllTransaction(fBlock, tBlock, this.selectNftObj.address, this.transferOrdersType).then(async (res) => {
                            if (res && res.length > 0) {
                                countData = arrData.concat(res.reverse());
                            }
                        });

                        // console.log("countData: ", countData);

                        if (countData.length > 0 && countData.length <= 20) {
                            this.ordersTotal = countData.length;
                            let list = [];
                            for (let i = 0; i < countData.length; i++) {
                                list.push(countData[i]);
                            }
                            this.ordersListData = list;
                        }

                        if (countData.length > 20) {
                            this.ordersListDataAll = countData;
                            this.ordersTotal = this.ordersListDataAll.length;
                        }
                    }

                    if (countData.length > 0) {
                        this.ordersListDataAll = countData;
                    }
                });

                this.isTransferOrdersRun = false;
            }

            if (this.ordersListDataAll.length > 0) {
                this.ordersTotal = this.ordersListDataAll.length;

                let start = (parseInt(pageNum) - 1) * 20;
                let end = parseInt(pageNum) * 20 - 1;
                let ordersList = [];

                for (let i = 0; i < this.ordersListDataAll.length; i++) {
                    if (i >= start && i <= end) {
                        ordersList.push(this.ordersListDataAll[i]);
                    }
                }

                this.ordersListData = ordersList;
            }

          // await metaMaskTools.getNftAllTransaction(this.selectNftObj.fromBlock, this.selectNftObj.address).then(async (res) => {
          //   if (res) {
          //     res = res.reverse();
          //     this.ordersTotal = res.length;
          //
          //     let start = (parseInt(pageNum) - 1) * 20;
          //     let end = parseInt(pageNum) * 20 - 1;
          //     let ordersList = [];
          //
          //     for (let i = 0; i < res.length; i++) {
          //       if (i >= start && i <= end) {
          //         ordersList.push(res[i]);
          //       }
          //     }
          //
          //     this.ordersListData = ordersList;
          //   }
          // });

        } catch (e) {
          console.log(e);
        }
        this.ordersListDataLoading = false;

        setTimeout(async () => {
          await this.setBitseaPageHeight();
        }, 10);
      },
      async initChartsPageData() {
        this.chartsListDataLoading = true;
        try {
        } catch (e) {
          console.log(e);
        }
        this.chartsListDataLoading = false;
      },
      closeBuyDialog() {
        this.collectionsBuyDialog = false;
        this.buyNftObj = {};
      },
      async onBuy(obj) {
        this.buyNftObj = {
          goodsId: obj.CGoodsID,
          nftName: obj.CName,
          nftId: obj.CTokenID,
          nftAddress: obj.CAddress,
          nftImg: obj.CUri,
          nftOwner: obj.COwner,
          nftPrice: obj.CPrice,
          serverFee: 0,
          total: 0
        };

        if (
            obj.CAddress.toString().toUpperCase() === "0X066466D7EAA56B60AAF0436DBDA6F92DB7BD2468" ||
            obj.CAddress.toString().toUpperCase() === "0X2704EC05C4491EBF54C9740396DD2E24F5F43BA2"
        ) {
            this.buyNftObj.nftImg = this.selectNftObj.tokenUri + obj.CTokenID + '.png';
        }

        this.collectionsBuyDialog = true;

        await metaMaskTools.getPtransfer().then(async (fee) => {
          this.buyNftObj.serverFee = parseFloat(parseInt(fee) / 10);
          this.buyNftObj.total = parseInt(obj.CPrice) * (parseFloat(parseInt(fee) / 10) / 100);
        });
      },
      async toBuy(obj) {
        try {
          if (this.$store.state.accounts.length > 0) {
            if (this.$store.state.accounts[0].toUpperCase() === obj.nftOwner.toUpperCase()) {
              this.$message.warning('Cannot purchase products that belong to oneself!');
              return;
            }
            if (parseFloat(BigNumberDiv(this.myBtcBalance, 1e18)) < parseFloat(BigNumberDiv(obj.nftPrice, 1e18))) {
              this.$message.warning('Your wallet balance is insufficient!');
              return;
            }

            await metaMaskTools.getGoodsInfo([obj.goodsId]).then(async (res) => {
              if (!(res && parseInt(res[2]) > 0)) {
                this.$message.warning('The product no longer exists, transaction failed!');
                return;
              }
            });

            let price = BigNumberMul(obj.nftPrice, 1);
            await metaMaskTools.onBuy([obj.goodsId], price).then(async (res) => {
                await this.closeBuyDialog();
                await this.initCheckbox();

                await this.$http.upchainok().then((res) => {
                    console.log(res);
                });

                await this.getBalance();
                await this.toSelectNftObj(this.selectNftObj, this.selectNftIndex);
            });
          } else {
            this.$message.warning('Please connect the wallet!');
          }
        } catch (e) {
          console.log(e);
        }
      },
      async offSell(goodsId) {
        try {
          if (this.$store.state.accounts.length > 0) {
            await metaMaskTools.offSell([goodsId]).then(async (res) => {
                await this.initCheckbox();

                await this.$http.upchainok().then((res) => {
                    console.log(res);
                });
                await this.toSelectNftObj(this.selectNftObj, this.selectNftIndex);
            });
          } else {
            this.$message.warning('Please connect the wallet!');
          }
        } catch (e) {
          console.log(e);
        }
      },
        buyHandleCheckAllChange(val) {
            this.buyCheckedCities = val ? this.buyCities : [];
            this.buyIsIndeterminate = false;
        },
        buyHandleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.buyCheckAll = checkedCount === this.buyCities.length;
            this.buyIsIndeterminate = checkedCount > 0 && checkedCount < this.buyCities.length;
        },
        async toBulkPurchase(list) {
            try {
                if (this.$store.state.accounts.length > 0) {
                    if (this.buyCheckedCities.length > 0) {
                        if (this.buyCheckedCities.length > 50) {
                            this.$message.warning('The quantity of a batch cannot exceed 50!');
                            return;
                        }

                        let priceCount = 0;
                        for (let n = 0; n < this.buyCheckedCities.length; n++) {
                            for (let i = 0; i < list.length; i++) {
                                if (parseInt(this.buyCheckedCities[n]) === parseInt(list[i].CGoodsID)) {
                                    if (this.$store.state.accounts[0].toUpperCase() === list[i].COwner.toUpperCase()) {
                                        this.$message.warning('Cannot purchase products that belong to oneself!');
                                        return;
                                    }

                                    priceCount = priceCount + parseInt(list[i].CPrice);
                                }
                            }
                        }

                        if (parseFloat(BigNumberDiv(this.myBtcBalance, 1e18)) < parseFloat(BigNumberDiv(priceCount, 1e18))) {
                            this.$message.warning('Your wallet balance is insufficient!');
                            return;
                        }

                        priceCount = BigNumberMul(priceCount, 1);

                        await metaMaskTools.allOnBuy([this.buyCheckedCities], priceCount).then(async (res) => {
                            await this.closeBuyDialog();
                            await this.initCheckbox();

                            await this.$http.upchainok().then((res) => {
                                console.log(res);
                            });

                            this.listedPageNum = 1;
                            await this.getBalance();
                            await this.toSelectNftObj(this.selectNftObj, this.selectNftIndex);
                        });
                    } else {
                        this.$message.warning('Please select the product!');
                    }
                } else {
                    this.$message.warning('Please connect the wallet!');
                }
            } catch (e) {
                console.log(e);
            }
        },
        initCheckbox() {
            this.buyCheckAll = false;
            this.buyIsIndeterminate = false;
            this.buyCheckedCities = [];
        }
    }
  }
</script>

<style lang="less">
  .bitsea_collections_page {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    .bitsea_bj_img {
      position: absolute;
      width: 100%;
      height: calc(100% - 84px);
      top: 84px;
      bottom: 0;
      left: 0;
    }
    .bitsea_collections_page_01 {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .bitsea_collections_page_02::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .bitsea_collections_page_02 {
      overflow-y: auto;
      position: absolute;
      width: 100%;
      top: 84px;
      bottom: 0;
      .bitsea_collections_page_02_divs {
        position: relative;
        width: 80%;
        top: 0;
        bottom: 0;
        left: 10%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        .bitsea_collections_page_021 {
          width: 100%;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: stretch;
          .bitsea_collections_page_0211 {
            width: 100%;
            border: 1px solid #515151;
            border-radius: 10px;
            background: #1a191a;
            margin-top: 100px;
            .bitsea_collections_page_0211_alls {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              margin: 10px;

              @media (max-width: 1023px){
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
              }
              .bitsea_collections_page_02111 {
                width: 16%;
                border: 1px solid #525151;
                border-radius: 10px;

                  @media (max-width: 1023px){
                      width: 82%;
                  }
              }
              .bitsea_collections_page_02112 {
                width: 82%;
                margin-left: 2%;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                .bitsea_collections_page_021121 {
                  margin-bottom: 15px;
                  margin-top: 10px;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                  align-items: center;
                  .bitsea_collections_page_0211211 {
                    height: 44px;
                    font-size: 36px;
                    font-family: Inter, Inter;
                    font-weight: bold;
                    color: #F7931A;
                    line-height: 44px;
                    -webkit-background-clip: text;
                    margin-right: 15px;

                      @media (max-width: 1023px){
                          width: fit-content;
                          height: auto;
                      }
                  }
                  .bitsea_collections_page_0211212 {
                    img {
                      margin: 10px 3px 0 3px;
                      cursor: pointer;
                    }
                  }
                }
                .bitsea_collections_page_021122 {
                  width: 100%;
                  margin: 2% 0;
                }
                .bitsea_collections_page_021123 {
                  font-size: 16px;
                  font-family: Inter, Inter;
                  font-weight: normal;
                  color: #667085;
                  line-height: 24px;
                  -webkit-background-clip: text;
                  white-space: pre-wrap;
                  text-align: justify;
                  margin-bottom: 25px;
                    @media (max-width: 1023px){
                        height: 80px;
                        overflow: hidden;
                    }
                }
                  .bitsea_collections_page_021123_pc {
                      font-size: 16px;
                      font-family: Inter, Inter;
                      font-weight: normal;
                      color: #667085;
                      line-height: 24px;
                      -webkit-background-clip: text;
                      white-space: pre-wrap;
                      text-align: justify;
                      margin-bottom: 25px;
                  }
                  .bitsea_collections_page_021123_01 {
                      display: none;
                      text-align: center;
                      color: white;
                      cursor: pointer;
                      margin-top: -5px;
                      height: 50px;
                      @media (max-width: 1023px){
                          display: block;
                      }
                  }
              }
            }
          }
          .bitsea_collections_page_0212 {
            width: 100%;
            border: 1px solid #515151;
            border-radius: 10px;
            background: #1a191a;
            margin-top: 20px;

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;

              @media (max-width: 1023px){
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                  align-items: center;
                  align-content: flex-start;
              }
            .bitsea_collections_page_02121 {
              .bitsea_collections_page_021211 {
                margin: 10px 30px 0 30px;
                font-size: 12px;
                font-family: Inter, Inter;
                font-weight: normal;
                color: #98A2B3;
                line-height: 16px;
                -webkit-background-clip: text;

                  @media (max-width: 1023px){
                      min-width: 115px;
                  }
              }
              .bitsea_collections_page_021212 {
                margin: 10px 30px 10px 30px;

                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;

                font-size: 14px;
                font-family: Inter, Inter;
                font-weight: normal;
                color: #FFFFFF;
                line-height: 20px;
                -webkit-background-clip: text;
                img {
                  width: 18px;
                  margin-left: 5px;
                }
              }
            }
          }
        }
        .bitsea_collections_page_022 {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: flex-start;

          width: 100%;
          margin-top: 45px;
          .bitsea_collections_page_0221 {
            width: 272px;
            height: 660px;
            background: #1c1c1ca6;
            border-radius: 10px;
            border: 1px solid #4b4b4b;
            margin: 20px 20px 70px 0;

              @media (max-width: 1023px){
                  display: none;
              }
            .bitsea_collections_page_02211 {
              width: 245px;
              height: 40px;
              border: 1px solid #555555;
              opacity: 0.97;
              border-radius: 25px;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              margin: 20px 12px;
              background: #343434;
              input {
                width: 199px;
                height: 40px;
                font-size: 15px;
                font-family: ArialRoundedMT;
                font-weight: bold;
                color: #a1a1a1;
                line-height: 40px;
                border-radius: 2px;
                border: 0px solid rgba(143,143,143,0);
                background: rgba(255, 255, 255, 0);
                margin-left: 15px;
                margin-bottom: 1px;
              }
              input::placeholder {
                color: #a1a1a1;
              }
              img {
                margin-left: 10px;
                cursor: pointer;
              }
            }
            .bitsea_collections_page_02212::-webkit-scrollbar {
              width: 0;
              height: 0;
            }
            .bitsea_collections_page_02212 {
              overflow-y: auto;
              height: 560px;
              .bitsea_collections_page_022121 {
                width: 245px;
                margin: 0 12px;
              }
            }
            .bitsea_collections_page_02213 {
              .bitsea_collections_page_023a121 {
                margin: 0 12px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                color: rgba(255,255,255,0.6);
                font-size: 12px;
              }
              .bitsea_collections_page_023a122::-webkit-scrollbar {
                width: 0;
                height: 0;
              }
              .bitsea_collections_page_023a122 {
                overflow-y: auto;
                height: 530px;
                margin: 10px 12px;
                .bitsea_collections_page_023a1221:hover {
                  background: rgba(17, 17, 17, 0.35);
                  border-radius: 5px;
                }
                .bitsea_collections_page_023a1221 {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: space-between;
                  align-items: center;
                  color: white;
                  margin: 5px 0;
                  cursor: pointer;
                  .bitsea_collections_page_023a12211 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    img {
                      width: 48px;
                    }
                    .bitsea_collections_page_023a122111 {
                      margin-left: 10px;
                      .bitsea_collections_page_023a1221111 {
                        font-size: 14px;
                        // font-family: Poppins, Poppins;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 14px;
                        -webkit-background-clip: text;
                      }
                      .bitsea_collections_page_023a1221112 {
                        font-size: 12px;
                        // font-family: Poppins, Poppins;
                        font-weight: 400;
                        color: rgba(255,255,255,0.8);
                        line-height: 12px;
                        -webkit-background-clip: text;
                        margin-top: 6px;
                      }
                    }
                  }
                  .bitsea_collections_page_023a12212 {
                    .bitsea_collections_page_023a122121 {
                      font-size: 13px;
                      // font-family: Poppins, Poppins;
                      font-weight: 600;
                      color: #FFFFFF;
                      line-height: 14px;
                      -webkit-background-clip: text;
                    }
                    .bitsea_collections_page_023a122122 {
                      font-size: 12px;
                      // font-family: Poppins, Poppins;
                      font-weight: 500;
                      color: #84ED73;
                      line-height: 12px;
                      -webkit-background-clip: text;
                      margin-top: 6px;
                    }
                  }
                }
              }
            }
          }
          .bitsea_collections_page_022_all {
            width: calc(100% - 292px);

              @media (max-width: 1023px){
                  width: 100%;
              }
            .bitsea_collections_page_022_all_00 {
                width: 58%;
                height: 32px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-around;
                align-items: center;

                border-radius: 20px;
                opacity: 0.9;
                border: 1px solid #3A3A3C;
                background-color: #f7931a;
                background-image: linear-gradient(#f7931a, #f7931a);
                img {
                    width: 14px;
                    margin-left: 10px;
                    margin-right: 10px;
                }
                div {
                    font-size: 15px;
                    font-family: Arial;
                    font-weight: bold;
                    color: white;
                    margin-left: 10px;
                }
            }
            .bitsea_collections_page_022_all_01 {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: space-between;
              align-items: center;
              .bitsea_collections_page_0222 {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                width: 260px;
                height: 45px;
                border-radius: 10px;
                margin: 21px 0;
                .bitsea_collections_page_0222_select {
                  color: white !important;
                  background-color: #f7931a !important;
                  background-image: linear-gradient(#f7931a, #f7931a) !important;
                }
                .bitsea_collections_page_0222_01:hover {
                  background-color: #f7931a;
                  background-image: linear-gradient(#f7931a, #f7931a);
                }
                .bitsea_collections_page_0222_01 {
                  cursor: pointer;
                  width: 107px;
                  height: 32px;
                  font-size: 14px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #ffffff;
                  text-align: center;
                  line-height: 32px;
                  border-radius: 20px;
                  background: #131313;
                  opacity: 0.9;
                  border: 1px solid #3A3A3C;
                }
                .bitsea_collections_page_0222_02:hover {
                  background-color: #f7931a;
                  background-image: linear-gradient(#f7931a, #f7931a);
                }
                .bitsea_collections_page_0222_02 {
                  cursor: pointer;
                  width: 107px;
                  height: 32px;
                  font-size: 14px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #ffffff;
                  text-align: center;
                  line-height: 32px;
                  border-radius: 20px;
                  margin: 0 15px;
                  background: #131313;
                  opacity: 0.9;
                  border: 1px solid #3A3A3C;
                }
                .bitsea_collections_page_0222_03:hover {
                  background-color: #f7931a;
                  background-image: linear-gradient(#f7931a, #f7931a);
                }
                .bitsea_collections_page_0222_03 {
                  cursor: pointer;
                  width: 107px;
                  height: 32px;
                  font-size: 14px;
                  font-family: Arial;
                  font-weight: bold;
                  color: #ffffff;
                  text-align: center;
                  line-height: 32px;
                  border-radius: 20px;
                  background: #131313;
                  opacity: 0.9;
                  border: 1px solid #3A3A3C;
                }
              }
              .bitsea_collections_page_022_all_011 {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                .bitsea_collections_page_022_all_0111 {
                  /*font-size: 14px;*/
                  /*font-family: Inter, Inter;*/
                  /*font-weight: 500;*/
                  /*color: #98A2B3;*/
                  /*line-height: 16px;*/
                  /*-webkit-background-clip: text;*/
                  /*margin-right: 50px;*/

                  width: 245px;
                  height: 32px;
                  border: 1px solid #555555;
                  opacity: 0.97;
                  border-radius: 25px;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  align-items: center;
                  justify-content: flex-start;
                  margin: 20px 12px;
                  background: #343434;
                  input {
                    width: 199px;
                    height: 32px;
                    font-size: 15px;
                    font-family: ArialRoundedMT;
                    font-weight: bold;
                    color: #a1a1a1;
                    line-height: 32px;
                    border-radius: 2px;
                    border: 0px solid rgba(143,143,143,0);
                    background: rgba(255, 255, 255, 0);
                    margin-left: 15px;
                    margin-bottom: 1px;
                  }
                  input::placeholder {
                    color: #a1a1a1;
                  }
                  img {
                    margin-left: 10px;
                    cursor: pointer;
                  }
                }
                .bitsea_collections_page_022_all_0112 {
                  /*cursor: pointer;*/
                  /*display: flex;*/
                  /*flex-direction: row;*/
                  /*flex-wrap: nowrap;*/
                  /*justify-content: flex-start;*/
                  /*align-items: center;*/

                  /*cursor: pointer;*/
                  /*font-size: 14px;*/
                  /*font-family: Roboto, Roboto;*/
                  /*font-weight: bold;*/
                  /*color: #FFFFFF;*/
                  /*line-height: 20px;*/
                  /*-webkit-background-clip: text;*/
                  /*margin-right: 10px;*/

                    position: relative;
                    width: 155px;
                    height: 32px;
                    line-height: 32px;

                    cursor: pointer;
                    font-size: 14px;
                    font-family: Roboto, Roboto;
                    font-weight: bold;
                    color: #FFFFFF;
                    -webkit-background-clip: text;
                    margin-right: 10px;
                    .bitsea_collections_page_022_all_01121_ss {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;

                        .bitsea_collections_page_022_all_01121 {
                            width: 135px;
                        }
                        img {
                            width: 14px;
                            margin-left: 5px;
                        }
                    }
                    .bitsea_collections_page_022_all_01121_ssAll {
                        position: absolute;
                        top: 45px;
                        width: 150px;
                        background: #e08517;
                        border-radius: 10px;
                        z-index: 99;

                        div:hover {
                            color: white;
                        }
                        div {
                            margin-left: 10px;
                            width: 100%;
                            color: #ffd3ae;
                        }
                    }
                }
                .bitsea_collections_page_022_all_0112_b {
                    position: relative;
                    width: 115px;
                    height: 32px;
                    line-height: 32px;

                    cursor: pointer;
                    font-size: 14px;
                    font-family: Roboto, Roboto;
                    font-weight: bold;
                    color: #FFFFFF;
                    -webkit-background-clip: text;
                    margin-right: 10px;
                    .bitsea_collections_page_022_all_01121_a {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-items: center;

                        .bitsea_collections_page_022_all_01121_b {
                            width: 95px;
                        }
                        img {
                            width: 14px;
                            margin-left: 5px;
                        }
                    }
                    .bitsea_collections_page_022_all_01121_all {
                        position: absolute;
                        top: 45px;
                        width: 115px;
                        position: absolute;
                        top: 45px;
                        width: 115px;
                        background: #e08517;
                        border-radius: 10px;
                        div:hover {
                            color: white;
                        }
                        div {
                            margin-left: 10px;
                            width: 100%;
                            color: #ffd3ae;
                        }
                    }
                }
              }
            }
              .bitsea_collections_page_0223_listing_01 {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: flex-end;
                  align-items: center;

                  @media (max-width: 1023px){
                      width: 100%;
                      justify-content: flex-start;
                  }
                  .bitsea_collections_page_0223_listing_011 {
                      cursor: pointer;
                      margin-right: 10px;
                      width: 115px;
                      height: 32px;
                      font-size: 14px;
                      font-family: Arial;
                      font-weight: bold;
                      color: #ffffff;
                      text-align: center;
                      line-height: 32px;
                      border-radius: 20px;
                      opacity: 0.9;
                      border: 1px solid #3A3A3C;
                      background-color: #f7931a !important;
                      background-image: linear-gradient(#f7931a, #f7931a) !important;
                  }
                  .bitsea_collections_page_0223_listing_012 {
                      cursor: pointer;
                      margin-left: 10px;
                      width: 115px;
                      height: 32px;
                      font-size: 14px;
                      font-family: Arial;
                      font-weight: bold;
                      color: #ffffff;
                      text-align: center;
                      line-height: 32px;
                      border-radius: 20px;
                      opacity: 0.9;
                      border: 1px solid #3A3A3C;
                      background-color: #f7931a !important;
                      background-image: linear-gradient(#f7931a, #f7931a) !important;
                  }
              }
            .bitsea_collections_page_0223 {
              width: 100%;
              margin-left: -15px;
              margin-bottom: 30px;

              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              align-content: flex-start;

                @media (max-width: 1023px){
                    margin-left: 0;
                    margin-bottom: 100px;
                    zoom: 0.85;
                }
              .bitsea_collections_page_0223_01 {
                position: relative;
                width: 205px;
                height: 345px;
                margin: 10px 15px;
                /*background: rgba(10, 10, 10, 0.4);*/
                border: 1px solid #525151;
                border-radius: 10px;
                /*box-shadow: 0 0 10px 10px #2c2c2ca1;*/
                .bitsea_collections_page_0223_011_bj {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 205px;
                  height: 345px;

                  -webkit-filter: blur(25px);
                  filter: blur(25px);
                }
                .bitsea_collections_page_0223_011 {
                  position: absolute;
                  width: 190px;
                  height: 190px;
                  margin: 6px;
                  border: 1px solid #525151;
                  border-radius: 10px;
                }
                  .bitsea_collections_page_0223_011_checkbox {
                      position: relative;
                      left: 15px;
                      top: 10px;
                  }
                .bitsea_collections_page_0223_012 {
                  position: absolute;
                  width: calc(100% - 16px);
                  top: 212px;
                  margin: 0 8px;
                  .collections_page_0223_0121 {
                    font-size: 14px;
                    font-family: Poppins, Poppins;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 14px;
                    -webkit-background-clip: text;

                  }
                  .collections_page_0223_0122 {
                    font-size: 12px;
                    font-family: Arial;
                    font-weight: 400;
                    color: #828282;
                    margin: 10px 0;
                  }
                  .collections_page_0223_0123 {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    .collections_page_0223_0123_01 {
                      display: flex;
                      flex-direction: row;
                      flex-wrap: nowrap;
                      justify-content: flex-start;
                      align-items: center;
                      margin-top: 10px;
                      img {
                        width: 18px;
                        height: 17px;
                        margin-right: 5px;
                      }
                      div {
                        font-size: 14px;
                        font-family: Arial;
                        font-weight: 400;
                        color: #FFFFFF;
                      }
                    }
                    .collections_page_0223_0123_02 {
                      margin-top: 10px;
                      font-size: 12px;
                      font-family: Arial;
                      font-weight: 400;
                      color: #84ED73;
                    }
                  }
                }
                .bitsea_collections_page_0223_013 {
                  position: absolute;
                  top: 280px;
                  width: 190px;
                  height: 50px;
                  margin-top: 15px;
                  margin-left: calc((100% - 190px) / 2);
                  cursor: pointer;
                }
                .bitsea_collections_page_0223_014 {
                  position: absolute;
                  top: 288px;
                  left: 12px;
                  width: 168px;
                  text-align: center;
                  color: white;
                  font-size: 13px;
                  font-weight: 600;
                  line-height: 26px;
                  height: 26px;
                  border-radius: 15px;
                  background: #f09a33;
                  margin-top: 15px;
                  margin-left: calc((100% - 190px) / 2);
                  cursor: pointer;
                }
              }
              .bitsea_collections_page_0223_02 {
                width: 100%;
                margin-top: 100px;
                color: white;
                text-align: center;
                font-size: 24px;
                font-weight: 600;
              }
            }
            .bitsea_collections_page_0223a_page {
              width: 100%;
              margin-bottom: 70px;
              text-align: center;
            }
            .bitsea_collections_page_0224 {
              width: 100%;
              border-radius: 15px;
              background: rgba(0, 0, 0, 0.2);
              margin-bottom: 30px;

                @media (max-width: 1023px){
                    overflow-x: auto;
                }
              .bitsea_collections_page_023b1 {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: flex-end;
                color: #e3e2e2;
                margin: 0 30px;
                height: 60px;
                line-height: 2;
                border-bottom: 1px solid #363636;

                  @media (max-width: 1023px){
                      border-bottom: 0px solid #363636;
                  }
                .bitsea_collections_page_023b11 {
                  width: 105px;
                  margin-bottom: 10px;
                }
                .bitsea_collections_page_023b12 {
                  width: 50px;
                  margin-bottom: 10px;
                }
                .bitsea_collections_page_023b13 {
                  width: 150px;
                  margin-bottom: 10px;
                }
                .bitsea_collections_page_023b14 {
                  width: 80px;
                  margin-bottom: 10px;
                }
                .bitsea_collections_page_023b15 {
                  width: 110px;
                  margin-bottom: 10px;
                }
                .bitsea_collections_page_023b16 {
                  width: 110px;
                  margin-bottom: 10px;
                }
                .bitsea_collections_page_023b17 {
                  width: 125px;
                  margin-bottom: 10px;
                }
              }
              .bitsea_collections_page_023b2 {
                width: 100%;
                .bitsea_collections_page_023b21 {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: space-between;
                  align-items: center;
                  color: white;
                  height: 70px;
                  margin: 10px 30px;
                  border-bottom: 1px solid #363636;
                  .bitsea_collections_page_023b211 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    .bitsea_collections_page_023b2111 {
                      margin-left: 20px;
                    }
                    img {
                      margin: 5px;
                    }
                    width: 105px;
                  }
                  .bitsea_collections_page_023b212 {
                    width: 50px;
                    margin-top: -5px;

                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;

                    img {
                      width: 45px;
                    }
                    div {
                      font-size: 13px;
                    }
                  }
                  .bitsea_collections_page_023b213 {
                    width: 150px;
                  }
                  .bitsea_collections_page_023b214 {
                    width: 80px;
                  }
                  .bitsea_collections_page_023b215 {
                    width: 110px;
                  }
                  .bitsea_collections_page_023b216 {
                    width: 110px;
                  }
                  .bitsea_collections_page_023b217 {
                    width: 125px;
                  }
                }
                .bitsea_collections_page_023b22 {
                  width: 100%;
                  line-height: 200px;
                  color: white;
                  text-align: center;
                  font-size: 24px;
                  font-weight: 600;
                }
              }
            }
            .bitsea_collections_page_0224a_page {
              width: 100%;
              margin-bottom: 70px;
              text-align: center;
            }
          }
        }
      }
      .bitsea_collections_page_03 {
        width: 100%;
      }
    }

    .collections_buy_dialog_modal {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: auto;
      margin: 0;
      background: rgba(19,19,19,0.5);
      /*border-radius: 16px 16px 16px 16px;*/
      /*border: 1px solid #F7931A;*/
      z-index: 2001;

        @media (max-width: 1023px){
            zoom: 0.8;
        }
      .collections_buy_dialog_div {
        position: relative;
        margin: 0 auto 50px;
        margin-top: 25vh;
        /*margin-left: calc((100% - 500px) / 2);*/

        width: 500px;
        height: 475px;
        background: #494949;
        box-shadow: 2px 2px 0px 0px #F7931A;
        border-radius: 16px;
        .collections_buy_dialog_div_01 {
          width: 500px;
          height: 70px;
          background: #494949;
          border-radius: 16px;

          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;
          div {
            font-size: 26px;
            font-family: Arial, Arial;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 35px;
            -webkit-background-clip: text;
            margin-left: 15px;
          }
          img {
            width: 30px;
            margin-right: 10px;
            cursor: pointer;
          }
        }
        .collections_buy_dialog_div_02 {
          margin: 0 13px;
          .collections_buy_dialog_div_021 {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 0 solid #646464;
            height: 142px;
            .collections_buy_dialog_div_0211 {
              width: 120px;
              margin-right: 15px;
            }
            .collections_buy_dialog_div_0212 {
              .collections_buy_dialog_div_02121 {
                font-size: 20px;
                font-family: Poppins, Poppins;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 23px;
                -webkit-background-clip: text;
              }
              .collections_buy_dialog_div_02122 {
                font-size: 14px;
                font-family: Poppins, Poppins;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 16px;
                -webkit-background-clip: text;
              }
            }
          }
          .collections_buy_dialog_div_022 {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: stretch;
            margin-top: 25px;
            .collections_buy_dialog_div_0221 {
              .collections_buy_dialog_div_02211 {
                font-size: 16px;
                font-family: Poppins, Poppins;
                font-weight: 400;
                color: rgba(255,255,255,0.6);
                line-height: 19px;
                -webkit-background-clip: text;
              }
              .collections_buy_dialog_div_02212 {
                font-size: 16px;
                font-family: Poppins, Poppins;
                font-weight: 400;
                color: rgba(255,255,255,0.6);
                line-height: 19px;
                -webkit-background-clip: text;
                margin: 10px 0;
              }
              .collections_buy_dialog_div_02213 {
                font-size: 20px;
                font-family: Poppins, Poppins;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 23px;
                -webkit-background-clip: text;
              }
            }
            .collections_buy_dialog_div_0222 {
              .collections_buy_dialog_div_02221 {
                font-size: 16px;
                font-family: Poppins, Poppins;
                font-weight: bold;
                color: rgba(255,255,255,0.6);
                line-height: 19px;
                -webkit-background-clip: text;
                text-align: right;
              }
              .collections_buy_dialog_div_02222 {
                font-size: 16px;
                font-family: Poppins, Poppins;
                font-weight: bold;
                color: rgba(255,255,255,0.6);
                line-height: 19px;
                -webkit-background-clip: text;
                margin: 10px 0;
                text-align: right;
              }
              .collections_buy_dialog_div_02223 {
                font-size: 20px;
                font-family: Poppins, Poppins;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 23px;
                -webkit-background-clip: text;
                text-align: right;
              }
            }
          }
          .collections_buy_dialog_div_023 {
            font-size: 16px;
            font-family: Poppins, Poppins;
            font-weight: 300;
            color: rgba(255,255,255,0.3);
            line-height: 19px;
            -webkit-background-clip: text;
            margin: 20px 0;
          }
        }
        .collections_buy_dialog_div_03 {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-around;
          align-items: center;
          border-top: 1px solid #6a6a6a;
          .collections_buy_dialog_div_031 {
            width: 40%;
            height: 45px;
            background: #181818;
            font-size: 18px;
            font-family: Inter, Inter;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 45px;
            text-align: center;
            border-radius: 20px;
            margin-top: 20px;
            cursor: pointer;
          }
          .collections_buy_dialog_div_032 {
            width: 40%;
            height: 45px;
            background: #FA951D;
            font-size: 18px;
            font-family: Inter, Inter;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 45px;
            text-align: center;
            border-radius: 20px;
            margin-top: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .bitsea_collections_page_drawer {
      height: calc(100% - 28px);
      .bitsea_collections_page_drawer_01 {
          margin-top: 27px;
          img {
              width: 25px;
              margin-bottom: -3px;
              margin-left: 30px;
          }
      }
      .bitsea_collections_page_drawer_02 {
          font-size: 24px;
          font-weight: bold;
          color: #FFFFFF;
          margin-top: 20px;
          margin-left: 12px;
      }
      .bitsea_collections_page_drawer_alls {
          width: 272px;
          height: 660px;
          background: #1c1c1ca6;
          border-radius: 10px;
          border: 1px solid #4b4b4b;
          margin: 20px 20px 70px 0;

          @media (max-width: 1023px){
              height: calc(100% - 190px);
              border: 0px solid #4b4b4b;
          }
          .bitsea_collections_page_02211 {
              width: 245px;
              height: 40px;
              border: 1px solid #555555;
              opacity: 0.97;
              border-radius: 25px;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: flex-start;
              margin: 20px 12px;
              background: #343434;
              input {
                  width: 199px;
                  height: 40px;
                  font-size: 15px;
                  font-family: ArialRoundedMT;
                  font-weight: bold;
                  color: #a1a1a1;
                  line-height: 40px;
                  border-radius: 2px;
                  border: 0px solid rgba(143,143,143,0);
                  background: rgba(255, 255, 255, 0);
                  margin-left: 15px;
                  margin-bottom: 1px;
              }
              input::placeholder {
                  color: #a1a1a1;
              }
              img {
                  margin-left: 10px;
                  cursor: pointer;
              }
          }
          .bitsea_collections_page_02212::-webkit-scrollbar {
              width: 0;
              height: 0;
          }
          .bitsea_collections_page_02212 {
              overflow-y: auto;
              height: 560px;
              .bitsea_collections_page_022121 {
                  width: 245px;
                  margin: 0 12px;
              }
          }
          .bitsea_collections_page_02213 {
              .bitsea_collections_page_023a121 {
                  margin: 0 12px;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  justify-content: space-between;
                  align-items: center;
                  color: rgba(255,255,255,0.6);
                  font-size: 12px;
              }
              .bitsea_collections_page_023a122::-webkit-scrollbar {
                  width: 0;
                  height: 0;
              }
              .bitsea_collections_page_023a122 {
                  overflow-y: auto;
                  height: 610px;
                  margin: 10px 12px;
                  .bitsea_collections_page_023a1221:hover {
                      background: rgba(17, 17, 17, 0.35);
                      border-radius: 5px;
                  }
                  .bitsea_collections_page_023a1221 {
                      display: flex;
                      flex-direction: row;
                      flex-wrap: nowrap;
                      justify-content: space-between;
                      align-items: center;
                      color: white;
                      margin: 5px 0;
                      cursor: pointer;
                      .bitsea_collections_page_023a12211 {
                          display: flex;
                          flex-direction: row;
                          flex-wrap: nowrap;
                          justify-content: flex-start;
                          align-items: center;
                          img {
                              width: 48px;
                          }
                          .bitsea_collections_page_023a122111 {
                              margin-left: 10px;
                              .bitsea_collections_page_023a1221111 {
                                  font-size: 14px;
                                  // font-family: Poppins, Poppins;
                                  font-weight: bold;
                                  color: #FFFFFF;
                                  line-height: 14px;
                                  -webkit-background-clip: text;
                              }
                              .bitsea_collections_page_023a1221112 {
                                  font-size: 12px;
                                  // font-family: Poppins, Poppins;
                                  font-weight: 400;
                                  color: rgba(255,255,255,0.8);
                                  line-height: 12px;
                                  -webkit-background-clip: text;
                                  margin-top: 6px;
                              }
                          }
                      }
                      .bitsea_collections_page_023a12212 {
                          .bitsea_collections_page_023a122121 {
                              font-size: 13px;
                              // font-family: Poppins, Poppins;
                              font-weight: 600;
                              color: #FFFFFF;
                              line-height: 14px;
                              -webkit-background-clip: text;
                          }
                          .bitsea_collections_page_023a122122 {
                              font-size: 12px;
                              // font-family: Poppins, Poppins;
                              font-weight: 500;
                              color: #84ED73;
                              line-height: 12px;
                              -webkit-background-clip: text;
                              margin-top: 6px;
                          }
                      }
                  }
              }
          }
      }
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #DB6531 !important;
    border-color: #DB6531 !important;
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #DB6531 !important;
  }
  .el-checkbox__inner:hover {
    border-color: #DB6531 !important;
  }
  .el-collapse-item__header {
    font-size: 16px;
    font-family: Inter, Inter;
    font-weight: normal;
    background-color: #ffffff00 !important;
    color: #ffffff !important;
    border-top: 1px solid #555555 !important;
    border-bottom: 0px solid #555555 !important;
  }
  .el-collapse {
    border-top: 0px solid #616161 !important;
    border-bottom: 0px solid #616161 !important;
  }
  .el-collapse-item__wrap {
    background-color: #fff0 !important;
    border-bottom: 0px solid #5b5b5b !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #FA951D !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #d0770f !important;
  }
  .el-message--warning {
    z-index: 2008 !important;
  }

  .el-checkbox__input.is-focus.el-checkbox__inner {
      border-color: #d0770f !important;
  }
  .el-checkbox__inner {
      border-color: #d0770f !important;
  }
  .el-checkbox__inner:hover {
      border-color: #d0770f;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #d0770f;
      border-color: #d0770f;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #d0770f;
  }

  .el-checkbox-group {
      font-size: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: center;
  }

    .el-checkbox__label {
        display: none;
    }
</style>
